module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"It's not you it's US","short_name":"It's US","start_url":"/?utm_source=A2HS&utm_medium=ItsUS","background_color":"#d7504e","theme_color":"#d7504e","display":"standalone","scope":"/","orientation":"portrait","icon":"static/medical-mask-600.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"088f82aaa3ec0940fedda282f44cab50"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/post","/404"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137372309-3","head":true,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
