require('./src/styles/uikit.less');

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `I've updated this app. ` +
      `Refresh for the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}


// const React = require("react")
// const { Helmet } = require("react-helmet")
// const _ = require("lodash");
//
// exports.onRenderBody = (
//   { setHeadComponents, setHtmlAttributes, setBodyAttributes },
//   pluginOptions
// ) => {
//   const helmet = Helmet.renderStatic()
//   setHtmlAttributes(helmet.htmlAttributes.toComponent())
//   setBodyAttributes(helmet.bodyAttributes.toComponent())
//   setHeadComponents([
//     helmet.title.toComponent(),
//     helmet.link.toComponent(),
//     helmet.meta.toComponent(),
//     helmet.noscript.toComponent(),
//     helmet.script.toComponent(),
//     helmet.style.toComponent(),
//   ])
// }
//
// exports.onPreRenderHTML = ({ getHeadComponents, replaceHeadComponents }) => {
//   const headComponents = getHeadComponents()
//
//   headComponents.sort((x, y) => {
//     if (x.props && x.props["data-react-helmet"]) {
//       return -1
//     } else if (y.props && y.props["data-react-helmet"]) {
//       return 1
//     }
//     return 0
//   })
//
//   replaceHeadComponents(omitDeep(headComponents, ["data-react-helmet"]))
// }
//
// const omitDeep = (collection, excludeKeys) =>
//     _.cloneDeepWith(collection, value => {
//         if (value && typeof value === "object") {
//             for (const key of excludeKeys) {
//                 try {
//                     delete value[key];
//                 } catch (_) {
//                     console.log("ignore", _);
//                 }
//             }
//         }
//     });
